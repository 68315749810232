import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Logo = styled.img`
  height: ${props => props.size};
`;

const SidebarLogo = ({
  size,
  style,
  className,
  selected,
  src,
  srcSelected,
}) => {
  if (selected) {
    return (
      <Logo src={srcSelected} className={className} alt="logo" size={size} style={style} />
    );
  }
  return (
    <Logo src={src} className={className} alt="logo" size={size} style={style} />
  );
};

SidebarLogo.propTypes = {
  size: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  selected: PropTypes.bool,
  src: PropTypes.string.isRequired,
  srcSelected: PropTypes.string.isRequired,
};

SidebarLogo.defaultProps = {
  size: '28px',
  style: {},
  className: '',
  selected: false,
};

export default SidebarLogo;