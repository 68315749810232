export const TASK_OPEN_NAME = 'TASK_OPEN_NAME';
export const TASK_OPEN_DESCRIPTION = 'TASK_OPEN_DESCRIPTION';
export const TASK_OPEN_MEMBERS = 'TASK_OPEN_MEMBERS';
export const TASK_OPEN_MILESTONE = 'TASK_OPEN_MILESTONE';
export const TASK_OPEN_LABELS = 'TASK_OPEN_LABELS';
export const TASK_OPEN_COMMITS = 'TASK_OPEN_COMMITS';
export const TASK_OPEN_ESTIMATED_HOURS = 'TASK_OPEN_ESTIMATED_HOURS';
export const TASK_OPEN_DEADLINE = 'TASK_OPEN_DEADLINE';
export const TASK_OPEN_STARTING_DATE = 'TASK_OPEN_STARTING_DATE';
export const TASK_OPEN_PRIORITY = 'TASK_OPEN_PRIORITY';
export const TASK_OPEN_ROADBLOCK = 'TASK_OPEN_ROADBLOCK';
export const TASK_OPEN_CHECKLIST = 'TASK_OPEN_CHECKLIST';
export const TASK_OPEN_EXPECTED_HOURS = 'TASK_OPEN_EXPECTED_HOURS';
export const TASK_SEND_ZMARTBOT_MESSAGE = 'TASK_SEND_ZMARTBOT_MESSAGE';

export const TASK_SEARCH = 'TASK_SEARCH';

export const TASK_OPEN_LOGS = 'TASK_OPEN_LOGS';
export const TASK_OPEN_REPORT_HOURS = 'TASK_OPEN_REPORT_HOURS';
export const TASK_OPEN_REPORTED_HOURS = 'TASK_OPEN_REPORTED_HOURS';
export const TASK_OPEN_COMMENTS = 'TASK_OPEN_COMMENTS';
export const TASK_OPEN_ATTACHMENT = 'TASK_OPEN_ATTACHMENT';
export const TASK_OPEN_ZMARTBOT_HELP = 'TASK_OPEN_ZMARTBOT_HELP';