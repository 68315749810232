import { fk, attr, Model } from 'redux-orm';

import {
  FETCH_CREATE_INVITATION,
  FETCH_GET_ALL_INVITATIONS,
  FETCH_CREATE_ACCEPT_INVITATION,
  FETCH_CREATE_REJECT_INVITATION,
} from '../../constants/api/invitations';

class Invitation extends Model {
  static create(props) {
    if (props.hasOwnProperty(this.idAttribute) && this.idExists(props[this.idAttribute])) {
      const model = this.withId(props.id);
      return model.update(props);
    }
    return super.create(props);
  }

  static reducer(action, modelClass) {
    const { type, payload } = action;
    switch (type) {
      case `${FETCH_CREATE_INVITATION}_SUCCESS`:
        break;
      case `${FETCH_CREATE_ACCEPT_INVITATION}_SUCCESS`:
        modelClass.withId(payload.body.id).delete();
        break;
      case `${FETCH_CREATE_REJECT_INVITATION}_SUCCESS`:
        modelClass.withId(payload.body.id).delete();
        break;
      case `${FETCH_GET_ALL_INVITATIONS}_SUCCESS`: {
        const array = payload.body.results || payload.body;
        for (let i = 0; i < array.length; i += 1) {
          const obj = array[i];
          modelClass.create(obj);
        }
        break;
      }
      default:
        break;
    }
    return null;
  }
}

Invitation.modelName = 'Invitation';

Invitation.fields = {
  id: attr(),
  sender_name: attr(),
  project_name: attr(),
  project_id: fk('Project', 'invitations'),
  project_description: attr(),
  date_sent: attr(),
  role: attr(),
};

export default Invitation;
