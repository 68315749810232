/* eslint-disable camelcase */
import {
  FETCH_AUTH_LOGIN,
  FETCH_AUTH_LOGOUT,
  FETCH_REGISTER,
  FETCH_GOOGLE_AUTH,
  FETCH_UPDATE_USER,
  FETCH_UPDATE_PHOTO,
} from '../constants/api/auth';

import {
  FETCH_GITHUB_CONFIRMATION,
} from '../constants/api/projects';

import {
  FETCH_MAX_PROJECTS,
} from '../constants/api/users';

const isBrowser = typeof window !== 'undefined';
const TOKEN_KEY = 'token';
const UID_KEY = 'uid';
const USER_MAIL = 'mail';
const CLIENT_KEY = 'client';
const EXPIRY_KEY = 'expiry';
const USER_KEY = 'userkey';
const SESSION_NAME = 'sessionname';
const SESSION_GITNAME = 'sessiongitname';
const SESSION_SLACKID = 'sessionslackid';

const SESSION_LABNAME = 'sessionlabname';
const SESSION_YEAR = 'sessionyear';
const SESSION_SEMESTER = 'sessionsemester';
const SESSION_SECTION = 'sessionsection';
const SESSION_GROUP = 'sessiongroup';

const SESSION_PROVIDER = 'sessionprovider';
const SESSION_FILE = 'sessionfile';
const SESSION_IMAGE = 'sessionimage';
const SESSION_TYPE = 'sessiontype';
const SESSION_MAX = 'sessionmax';
const WATCHED_TUTORIAL = 'watchedtutorial';
const browserToken = isBrowser ? window.localStorage.getItem(TOKEN_KEY) : null;
const browserHasToken = Boolean(browserToken);
const browserUser = browserHasToken ? browserToken : null;
const browserUid = isBrowser ? window.localStorage.getItem(UID_KEY) : null;
const browserMail = isBrowser ? window.localStorage.getItem(USER_MAIL) : null;
const browserClient = isBrowser ? window.localStorage.getItem(CLIENT_KEY) : null;
const browserExpiry = isBrowser ? window.localStorage.getItem(EXPIRY_KEY) : null;
const userKey = isBrowser ? window.localStorage.getItem(USER_KEY) : null;
const sessionName = isBrowser ? window.localStorage.getItem(SESSION_NAME) : '';

const sessionLabname = isBrowser ? window.localStorage.getItem(SESSION_LABNAME) : '';
const sessionYear = isBrowser ? window.localStorage.getItem(SESSION_YEAR) : '';
const sessionSemester = isBrowser ? window.localStorage.getItem(SESSION_SEMESTER) : '';
const sessionSection = isBrowser ? window.localStorage.getItem(SESSION_SECTION) : '';
const sessionGroup  = isBrowser ? window.localStorage.getItem(SESSION_GROUP) : '';

const sessionGitname = isBrowser ? window.localStorage.getItem(SESSION_GITNAME) : '';
const sessionSlackid = isBrowser ? window.localStorage.getItem(SESSION_SLACKID) : '';
const sessionProvider = isBrowser ? window.localStorage.getItem(SESSION_PROVIDER) : null;
const sessionFile = isBrowser ? window.localStorage.getItem(SESSION_FILE) : null;
const sessionImage = isBrowser ? window.localStorage.getItem(SESSION_IMAGE) : null;
const sessionType = isBrowser ? window.localStorage.getItem(SESSION_TYPE) : null;
const sessionMax = isBrowser ? window.localStorage.getItem(SESSION_MAX) : null;
const watchedTutorial = Boolean(isBrowser ? window.localStorage.getItem(WATCHED_TUTORIAL) : null);

export default (state = {
  isFetching: false,
  isAuthenticated: browserHasToken,
  user: browserUser,
  email: browserMail,
  token: browserToken,
  client: browserClient,
  uid: browserUid,
  expiry: browserExpiry,
  errorMessage: '',
  errorEmail: '',
  errorPassword: '',
  id: userKey,
  name: sessionName,
  gitname: sessionGitname,
  slack_id: sessionSlackid,
  labname: sessionLabname,
  year: sessionYear,
  semester: sessionSemester,
  section: sessionSection,
  group: sessionGroup,
  provider: sessionProvider,
  file: sessionFile,
  image: sessionImage,
  accountType: sessionType,
  maxProjects: sessionMax,
  watchedTutorial: watchedTutorial
}, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_REGISTER}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        errorMessage: '',
        errorEmail: '',
        errorPassword: '',
      };
    } case `${FETCH_REGISTER}_FAILURE`: {
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: '',
        errorEmail: '',
        errorPassword: '',
      };
    } case `${FETCH_REGISTER}_SUCCESS`: {
      return {
        ...state,
        isFetching: false,
      };
    } case `${FETCH_AUTH_LOGIN}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        errorMessage: '',
        errorEmail: '',
        errorPassword: '',
      };
    } case `${FETCH_AUTH_LOGIN}_SUCCESS`: {
      const {
        name,
        gitname,
        slack_id,
        labname,
        year,
        semester,
        section,
        group,
        provider,
        file,
        image,
        account_type,
        max_projects,
        email,
        watched_tutorial
      } = payload.body.data;
      console.log("Receibed watched", watched_tutorial)
      const loginToken = payload.headers.get('access-token');
      const uidToken = payload.headers.get('uid');
      const clientToken = payload.headers.get('client');
      const expiryToken = payload.headers.get('expiry');
      const userId = payload.body.data.id;
      window.localStorage.setItem(TOKEN_KEY, loginToken);
      window.localStorage.setItem(UID_KEY, uidToken);
      window.localStorage.setItem(USER_MAIL, email);
      window.localStorage.setItem(CLIENT_KEY, clientToken);
      window.localStorage.setItem(EXPIRY_KEY, expiryToken);
      window.localStorage.setItem(USER_KEY, userId);
      window.localStorage.setItem(SESSION_NAME, name);

      window.localStorage.setItem(SESSION_LABNAME, labname);
      window.localStorage.setItem(SESSION_YEAR, year);
      window.localStorage.setItem(SESSION_SEMESTER, semester);
      window.localStorage.setItem(SESSION_SECTION, section);
      window.localStorage.setItem(SESSION_GROUP, group);

      window.localStorage.setItem(SESSION_GITNAME, gitname);
      window.localStorage.setItem(SESSION_SLACKID, slack_id);
      window.localStorage.setItem(SESSION_PROVIDER, provider);
      window.localStorage.setItem(SESSION_FILE, file);
      window.localStorage.setItem(SESSION_IMAGE, image);
      window.localStorage.setItem(SESSION_TYPE, account_type);
      window.localStorage.setItem(SESSION_MAX, max_projects);
      window.localStorage.setItem(WATCHED_TUTORIAL, watched_tutorial);
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        token: loginToken,
        client: clientToken,
        uid: uidToken,
        expiry: expiryToken,
        id: userId,
        user: false,
        errorMessage: '',
        errorEmail: '',
        errorPassword: '',
        name,
        email,
        gitname,
        slack_id,
        labname,
        year,
        semester,
        section,
        group,
        provider,
        file,
        image,
        accountType: account_type,
        maxProjects: max_projects,
        watchedTutorial: watched_tutorial
      };
    } case `${FETCH_GOOGLE_AUTH}_SUCCESS`: {
      const {
        name,
        gitname,
        slack_id,
        labname,
        year,
        semester,
        section,
        group,
        provider,
        file,
        image,
        account_type,
        max_projects,
        email,
      } = payload.body.data;
      const loginToken = payload.headers.get('access-token');
      const uidToken = payload.headers.get('uid');
      const clientToken = payload.headers.get('client');
      const expiryToken = payload.headers.get('expiry');
      const userId = payload.body.data.id;
      window.localStorage.setItem(TOKEN_KEY, loginToken);
      window.localStorage.setItem(UID_KEY, uidToken);
      window.localStorage.setItem(USER_MAIL, email);
      window.localStorage.setItem(CLIENT_KEY, clientToken);
      window.localStorage.setItem(EXPIRY_KEY, expiryToken);
      window.localStorage.setItem(USER_KEY, userId);
      window.localStorage.setItem(SESSION_NAME, name);
      window.localStorage.setItem(SESSION_LABNAME, labname);
      window.localStorage.setItem(SESSION_YEAR, year);
      window.localStorage.setItem(SESSION_SEMESTER, semester);
      window.localStorage.setItem(SESSION_SECTION, section);
      window.localStorage.setItem(SESSION_GROUP, group);
      window.localStorage.setItem(SESSION_GITNAME, gitname);
      window.localStorage.setItem(SESSION_SLACKID, slack_id);
      window.localStorage.setItem(SESSION_PROVIDER, provider);
      window.localStorage.setItem(SESSION_FILE, file);
      window.localStorage.setItem(SESSION_IMAGE, image);
      window.localStorage.setItem(SESSION_TYPE, account_type);
      window.localStorage.setItem(SESSION_MAX, max_projects);
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        token: loginToken,
        client: clientToken,
        uid: uidToken,
        expiry: expiryToken,
        id: userId,
        user: false,
        errorMessage: '',
        errorEmail: '',
        errorPassword: '',
        name,
        email,
        gitname,
        slack_id,
        labname,
        year,
        semester,
        section,
        group,
        provider,
        file,
        image,
        accountType: account_type,
        maxProjects: max_projects,
      };
    } case `${FETCH_AUTH_LOGIN}_FAILURE`: {
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        token: null,
        client: null,
        uid: null,
        expiry: null,
        user: false,
        errorMessage: '',
        errorEmail: '',
        errorPassword: '',
      };
    } case `${FETCH_UPDATE_USER}_SUCCESS`: {
      window.localStorage.setItem(SESSION_NAME, payload.body.name);
      window.localStorage.setItem(SESSION_LABNAME, payload.body.labname);
      window.localStorage.setItem(SESSION_YEAR, payload.body.year);
      window.localStorage.setItem(SESSION_SEMESTER, payload.body.semester);
      window.localStorage.setItem(SESSION_SECTION, payload.body.section);
      window.localStorage.setItem(SESSION_GROUP, payload.body.group);
      window.localStorage.setItem(SESSION_GITNAME, payload.body.gitname);
      window.localStorage.setItem(SESSION_SLACKID, payload.body.slack_id);
      return {
        ...state,
        name: payload.body.name,
        gitname: payload.body.gitname,
        slack_id: payload.body.slack_id,
        labname: payload.body.labname,
        year: payload.body.year,
        semester: payload.body.semester,
        section: payload.body.section,
        group: payload.body.group,
      };
    } case `${FETCH_GITHUB_CONFIRMATION}_SUCCESS`: {
      window.localStorage.setItem(SESSION_GITNAME, payload.body.gitname);
      return {
        ...state,
        gitname: payload.body.gitname,
      };
    } case `${FETCH_UPDATE_PHOTO}_SUCCESS`: {
      window.localStorage.setItem(SESSION_IMAGE, payload.body.image);
      return {
        ...state,
        image: payload.body.image,
      };
    } case `${FETCH_MAX_PROJECTS}_SUCCESS`: {
      if (payload.body.email === state.uid) {
        window.localStorage.setItem(SESSION_MAX, payload.body.max_projects);
        return {
          ...state,
          maxProjects: payload.body.max_projects,
        };
      }
      return {
        ...state,
      };
    } case `${FETCH_AUTH_LOGOUT}_REQUEST`: {
      if (isBrowser) {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.removeItem(UID_KEY);
        window.localStorage.removeItem(USER_MAIL);
        window.localStorage.removeItem(CLIENT_KEY);
        window.localStorage.removeItem(USER_KEY);
        window.localStorage.removeItem(SESSION_NAME);
        window.localStorage.removeItem(SESSION_LABNAME);
        window.localStorage.removeItem(SESSION_YEAR);
        window.localStorage.removeItem(SESSION_SEMESTER);
        window.localStorage.removeItem(SESSION_SECTION);
        window.localStorage.removeItem(SESSION_GROUP);
        window.localStorage.removeItem(SESSION_GITNAME);
        window.localStorage.removeItem(SESSION_SLACKID);
        window.localStorage.removeItem(SESSION_PROVIDER);
        window.localStorage.removeItem(SESSION_FILE);
        window.localStorage.removeItem(SESSION_IMAGE);
        window.localStorage.removeItem(SESSION_TYPE);
        window.localStorage.removeItem(SESSION_MAX);
      }
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        token: null,
        uid: null,
        email: '',
        client: null,
        id: null,
        user: null,
        expiry: null,
        errorMessage: '',
        errorEmail: '',
        errorPassword: '',
        name: '',
        labname: '',
        year: '',
        semester: '',
        section: '',
        group: '',
        gitname: '',
        slack_id: '',
        provider: null,
        file: null,
        image: null,
        accountType: null,
        maxProjects: null,
      };
    } default:
      return state;
  }
};
