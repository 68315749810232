import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
    chatCustomTitle: {
        backgroundColor: '#ff4729',
        padding: '0px',
    },
    chatCustomSendButton: {
        borderRadius: '15px',
    },
    chatCustomChatContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
});

export default useStyles;