import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { GoogleLogin } from 'react-google-login';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Background from '../../assets/background.png';
import Form from '../lib/Form';
import TextField from '../lib/TextField';
import PrimaryButton from '../lib/Button/PrimaryButton';
import CardPanel from '../lib/Card';
import Container from './Container';
import { login, googleAuth } from '../../actions/api/auth';
import { notifyError } from '../../actions/notification';
import language from '../../constants/language';

const styles = {
  root: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: `url(${Background})`,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formTitle: {
    paddingLeft: '2.5%',
    paddingTop: '3%',
  },
  formInput: {
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingBottom: '5%',
  },
  welcome: {
    marginBottom: 20,
    color: 'white',
    fontSize: 20,
  },
  title: {
    marginBottom: 60,
    color: 'white',
    fontSize: 50,
    fontWeight: 'bold',
    alignItems: 'felx-end',
  },
  forgot: {
    color: '#298fca',
    fontSize: '14px',
    fontFamily: 'Roboto,arial,sans-serif',
  },
  logo: {
    color: '#ea5941',
    fontSize: 80,
    marginBottom: -13,
    marginRight: 10,
  },
  googleButton: {
    borderRadius: 0,
    height: '36px',
    background: 'white',
    color: '#737373',
    borderColor: 'gainsboro',
    '&:hover': {
      backgroundColor: 'rgba(0.1,0.1,0.1,0.1)',
    },
  },
  googleButton__text: {
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: '0 10px',
    paddingTop: '5px',
    fontSize: '14px',
    fontFamily: 'Roboto,arial,sans-serif',
  },
  googleButton__icon: {
    paddingTop: '3px',
    fontSize: '14px',
  },
};

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: '',
        password: '',
      },
    };

    this.handleEmail = this.handleEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGoogle = this.handleGoogle.bind(this);
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  handleEmail(event) {
    const value = event;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        email: value,
      },
    });
  }

  handlePassword(event) {
    const value = event;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        password: value,
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { user } = this.state;
    const { selectedLanguage } = this.props;
    if (user.email && user.password) {
      this.props.login(user.email, user.password);
    } else {
      this.props.notifyError(language[selectedLanguage].login.incompleteCred);
    }
  }

  handleGoogle(response) {
    this.props.googleAuth({ accessToken: response.accessToken, url: window.location.href });
  }

  render() {
    const { user } = this.state;
    const { classes, selectedLanguage } = this.props;
    if (this.props.isAuthenticated) {
      return <Redirect to="/welcome" />;
    }
    return (
      <Container>
        <CardPanel>
          <Typography className={classes.formTitle} variant="headline" component="h2">
            {language[selectedLanguage].login.login}
          </Typography>
          <Form
            onSubmit={this.handleSubmit}
            className={classes.formInput}
          >
            <TextField
              onChange={this.handleEmail}
              type="email"
              name={user.email}
              fullWidth
              label={language[selectedLanguage].login.email}
              required
              id="user"
              autoFocus
            />
            <TextField
              onChange={this.handlePassword}
              type="password"
              name={user.password}
              fullWidth
              label={language[selectedLanguage].login.password}
              required
              id="password"
            />
            <Link to="/forgot" style={{ float: 'right', paddingBottom: '10px' }} className={classes.forgot}>
              {language[selectedLanguage].login.forgotPass}
            </Link>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <PrimaryButton
                type="submit"
              >
                {language[selectedLanguage].login.signin}
              </PrimaryButton>
              <GoogleLogin
                className={classes.googleButton}
                clientId="624738581574-47qb8rlu0ub9hijb9rn6n59642qfj6ok.apps.googleusercontent.com"
                onSuccess={this.handleGoogle}
              >
                <div style={{
                  display: 'flex', flexDirection: 'row', justifyContent: 'center',
                }}
                >
                  <div className={classes.googleButton__icon}>
                    <img width="20px" alt="Google &quot;G&quot; Logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
                  </div>
                  <div className={classes.googleButton__text}>
                    {language[selectedLanguage].login.googleLogin}
                  </div>
                </div>
              </GoogleLogin>
            </div>
          </Form>
        </CardPanel>
      </Container>
    );
  }
}

LoginContainer.propTypes = {
  classes: PropTypes.any.isRequired,
  login: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  googleAuth: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    errorMsg: state.auth.errorMessage,
    isAuthenticated: state.auth.isAuthenticated,
    successMessage: state.auth.successMessage,
    selectedLanguage: state.user.language,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ login, notifyError, googleAuth }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginContainer));
