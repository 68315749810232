import { fk, attr, Model } from 'redux-orm';

import {
  FETCH_ADD_HOURS,
  FETCH_GET_HOURS,
  FETCH_DELETE_HOUR,
} from '../../constants/api/hours';

class Hour extends Model {
  static create(props) {
    if (props.hasOwnProperty(this.idAttribute) && this.idExists(props[this.idAttribute])) {
      const model = this.withId(props.id);
      return model.update(props);
    }
    return super.create(props);
  }

  static reducer(action, modelClass) {
    const { type, payload } = action;
    switch (type) {
      case `${FETCH_ADD_HOURS}_SUCCESS`: {
        const body = { ...payload.body, id: payload.body.id };
        modelClass.create(body);
        break;
      }
      case `${FETCH_GET_HOURS}_SUCCESS`: {
        const array = payload.body;
        for (let i = 0; i < array.length; i += 1) {
          const obj = array[i];
          modelClass.create(obj);
        }
        break;
      }
      case `${FETCH_DELETE_HOUR}_SUCCESS`: {
        modelClass.withId(payload.body.id).delete();
        break;
      }
      default:
        break;
    }
    return null;
  }
}

Hour.modelName = 'Hour';

Hour.fields = {
  id: attr(),
  hours: attr(),
  date: attr(),
  user_id: attr(),
  task_id: fk('Task', 'hours'),
};

export default Hour;
