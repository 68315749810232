// in ActionProvider.jsx
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

const URL = process.env.REACT_APP_API_URL

const Entregas = ({ auth }) => {
  const [semester, setSemester] = useState(2);
  const [year, setYear] = useState(2022);
  const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getSemester();
    getCicles();
  }, []);

  const getSemester = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    setYear(year);
    if (month >= 0 && month <= 5) {
      setSemester(1);
    } else {
      setSemester(2);
    }
  }

  const getCicles = async () => {
    const response = await fetch(`${URL}/chatbot_cicles?semester=${semester}&year=${year}`, {
      method: 'GET',
      headers: {
        uid: auth.uid,
        client: auth.client,
        'access-token': auth.token
      },
    })
    const data = await response.json();
    setData(data);
  }

  return (
    <div className='react-chatbot-kit-chat-bot-message-container'>
      <div className='react-chatbot-kit-chat-bot-message'>
        <strong>Entregas del Semestre {year}-{semester}</strong>
        <br/>
        <br/>
        Ciclo 1 termina el {data.cicle1} <br/>
        Ciclo 2 termina el {data.cicle2} <br/>
        Ciclo 3 termina el {data.cicle3}
    </div>  
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    auth: state.auth,
  };
}


export default connect(mapStateToProps)(Entregas);