import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';

import Settings from '@material-ui/icons/Settings';
import Dashboard from '@material-ui/icons/Dashboard';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ContactsIcon from '@material-ui/icons/Contacts';

import CustomSetting from '../lib/Menu/headermenu';
import CustomMenuItem from '../lib/Menu/MenuItem/index';

import {
  toggleDropDownSetting,
} from '../../actions/user';

const styles = {
  icon: {
    color: '#2a3d41',
    fontSize: 20,
  },
};

class DropdownComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
    this.onClick = this.onClick.bind(this);
    this.handleSettings = this.handleSettings.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleDashboard = this.handleDashboard.bind(this);
    this.handleMyProfile = this.handleMyProfile.bind(this);
  }

  onClick() {
    this.props.toggleDropDownSetting();
  }

  handleSettings() {
    this.setState({ redirect: '/settings' });
  }

  handleLogout() {
    this.setState({ redirect: '/logout' });
  }

  handleDashboard() {
    this.setState({ redirect: '/dashboard' });
  }

  handleMyProfile() {
    this.setState({ redirect: '/myprofile' });
  }

  render() {
    const {
      classes, showDropDownSetting, accountType,
    } = this.props;
    const open = (showDropDownSetting);
    if (this.state.redirect) {
      this.setState({ redirect: false });
      return (<Redirect to={this.state.redirect} />);
    }
    return (
      <Fragment>
        <CustomSetting open={open} onClick={this.onClick}>
          {(accountType !== 'user')
            && (
              <CustomMenuItem id="Dashboard" onClick={this.handleDashboard}>
                <Dashboard className={classes.icon} />

                &nbsp;Dashboard
              </CustomMenuItem>
            )}
          <CustomMenuItem id="Settings" onClick={this.handleSettings}>
            <Settings className={classes.icon} />

            &nbsp;Settings
          </CustomMenuItem>

          <CustomMenuItem id="Logout" onClick={this.handleMyProfile}>
            <ContactsIcon className={classes.icon} />

            &nbsp;MyProfile
          </CustomMenuItem>
          
          <CustomMenuItem id="Logout" onClick={this.handleLogout}>
            <ExitToApp className={classes.icon} />

            &nbsp;Logout
          </CustomMenuItem>
        </CustomSetting>
      </Fragment>
    );
  }
}

DropdownComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDropDownSetting: PropTypes.func.isRequired,
  showDropDownSetting: PropTypes.bool.isRequired,
  accountType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    showDropDownSetting: state.user.showDropDownSetting,
    accountType: state.auth.accountType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleDropDownSetting,
    }, dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DropdownComponent));
