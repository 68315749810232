import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';

class CustomMenuItem extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(ev) {
    if (this.props.onClick != null) {
      this.props.onClick(ev);
    }
  }

  render() {
    const { id, children, ...props } = this.props;
    return (
      <MenuItem {...props} value={id} onClick={this.onClick}>{children}</MenuItem>
    );
  }
}

CustomMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.any.isRequired,
};

CustomMenuItem.defaultProps = {
  onClick: null,
};

export default CustomMenuItem;
