import React from 'react';
import PropTypes from 'prop-types';

import MaterialInputLabel from '@material-ui/core/InputLabel';

const InputLabel = ({
  htmlFor,
  children,
  ...rest
}) => {
  return (
    <MaterialInputLabel
      htmlFor={htmlFor}
      {...rest}
    >
      {children}
    </MaterialInputLabel>
  );
};

InputLabel.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
};

export default InputLabel;
