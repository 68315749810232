import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
};

class CardPanel extends Component {
  render() {
    const {
      classes, ...rest
    } = this.props;
    const card = (
      <Card
        className={classes.card}
        {...rest}
      />
    );
    return card;
  }
}

CardPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CardPanel);
