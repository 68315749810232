import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import language from '../../../constants/language';
import Dialog from '../../lib/Dialog';
import CircularProgress from '../../lib/CircularProgress';

const isProduction = true;

const NetworkModal = ({ children, online, selectedLanguage }) => {
  return (
    <Fragment>
      {children}
      {
        !online
        && isProduction
        && (
          <Fragment>
            <Dialog
              open
              canClose={false}
              title={language[selectedLanguage].app.noConnection}
            >
              <CircularProgress style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                position: 'static',
              }}
              />
            </Dialog>
          </Fragment>

        )
      }
    </Fragment>
  );
};

NetworkModal.propTypes = {
  children: PropTypes.array.isRequired,
  online: PropTypes.bool.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.user.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkModal);
