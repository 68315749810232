import React, { useState } from "react";
import "./SideBar.css"

function SideBar({ isCollapsed }) {

    const [showArchivedChats, setShowArchivedChats] = useState(false);

    const toggleArchivedChats = () => {
        setShowArchivedChats(!showArchivedChats);
    };

    return (
      <div className={`${isCollapsed ? 'collapsed' : ''} chatmodal-sidebar`}>
        <div className="sidebar-create-new-chat">
          <button className="sidebar-button">Nuevo chat</button>
        </div>
        <div className="sidebar-chats">
          <h2>Test</h2>
          <h2>Test</h2>
          <h2>Test</h2>
        </div>
        <div className="sidebar-archived-chats">
          <button className="sidebar-button" onClick={toggleArchivedChats}>Archivados</button>
        </div>
        <div className={`sidebar-archived-list ${showArchivedChats ? 'show' : ''}`}>
          <h2>Archivado 1</h2>
          <h2>Archivado 2</h2>
          <h2>Archivado 3</h2>
        </div>
      </div>
    );
};

export default SideBar;