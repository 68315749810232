import {
  TOGGLE_ARCHIVE_MODAL_PROJECT,
  SELECT_ARCHIVED_BOARD,
  SET_FETCHING_ARCHIVED,
} from '../../constants/modals/archived';

export default (state = {
  selectedProject: '',
  selectedBoardId: '',
  isFetching: false,
}, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_ARCHIVE_MODAL_PROJECT: {
      const selectedProject = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        selectedProject,
      };
    }
    case SELECT_ARCHIVED_BOARD: {
      const { body } = payload;
      let { id } = body;
      if (state.selectedBoardId === id) {
        id = '';
      }
      return {
        ...state,
        selectedBoardId: id,
      };
    }
    case SET_FETCHING_ARCHIVED: {
      const setBool = payload.body.bool ? payload.body.bool : false;
      return {
        ...state,
        isFetching: setBool,
      };
    }
    default: {
      return state;
    }
  }
};
