import { createSelector } from 'redux-orm';
import orm from '../reducers/orm';

export const allInvitations = createSelector(
  orm,
  (_, state) => state.orm,
  (session) => {
    return session.Invitation
      .all()
      .orderBy(o => o.date_sent)
      .toRefArray();
  },
);

export const allInvitationsSent = createSelector(
  orm,
  (_, state) => state.orm,
  (_, state) => state.modal.invitations.selectedProject,
  (session, projectId) => {
    return session.InvitationSent
      .filter(o => o.project_id === projectId)
      .toRefArray();
  },
);
