import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MaterialInput from '@material-ui/core/Input';

/* eslint-disable react/require-default-props */
class Input extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { value } = event.target;
    const { onChange, maxLength } = this.props;
    if (maxLength != null) {
      if (maxLength < value.length) return;
    }
    if (onChange != null) {
      onChange(value);
    }
  }

  render() {
    const { ...props } = this.props;
    return (
      <MaterialInput
        {...props}
        onChange={this.onChange}
      />
    );
  }
}

Input.propTypes = {
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onSubmit: PropTypes.func,
  multiline: "true",
  rows: "10",

};

export default Input;
