import {
  TOGGLE_EDIT_MODAL_COLUMN,
  TOGGLE_DELETE_MODAL_COLUMN,
} from '../../constants/modals/columns';

export default (state = {
  selectedColumn: '',
  columnEditing: false,
  columnDeleting: false,
}, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_EDIT_MODAL_COLUMN: {
      const columnEditing = !state.columnEditing;
      const selectedColumn = payload.body.id ? payload.body.id : '';

      return {
        ...state,
        columnEditing,
        selectedColumn,
      };
    }
    case TOGGLE_DELETE_MODAL_COLUMN: {
      const columnDeleting = !state.columnDeleting;
      const selectedColumn = payload.body.id ? payload.body.id : '';

      return {
        ...state,
        columnDeleting,
        selectedColumn,
      };
    }
    default: {
      return state;
    }
  }
};
