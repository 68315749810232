import React, { Fragment, Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AcceptIcon from '@material-ui/icons/Done';
import RejectIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import { truncateString } from '../../../utils';
import { allFolders } from '../../../selectors/folders';
import CircularProgress from '../../lib/CircularProgress';
import { notifySuccess } from '../../../actions/notification';
import { fetchGetInvitations, fetchRejectInvitation, fetchAcceptInvitation } from '../../../actions/api/invitations';
import { allInvitations } from '../../../selectors/invitations';
import BlankProfile from '../../../assets/blankprofile.png';
import language from '../../../constants/language';

const styles = {
  container: {
    backgroundColor: 'white',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  loadingContainer: {
    backgroundColor: 'rgb(249, 249, 249)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    marginTop: 10,
  },
  title: {
    color: '#2a3d41',
    fontWeight: 'bold',
    fontSize: 16,
  },
  name: {
    color: '#2a3d41',
    fontWeight: 'bold',
    fontSize: 15,
  },
  text: {
    color: '#2a3d41',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0px',
  },
  special: {
    color: '#2a3d41',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStyle: 'italic',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0px',
  },
  notification: {
    margin: 10,
    padding: 3,
  },
  grow: {
    flexGrow: 1,
  },
  header: {
    margin: '10px 0px',
  },
  thumbnail: {
    marginLeft: 3,
    marginRight: 6,
  },
  headerText: {
    paddingLeft: '1.5%',
    color: '#2a3d41',
    fontWeight: 'bold',
  },
  content: {
    color: '#2a3d41',
  },
  date: {
    marginRight: 3,
    fontSize: 12,
    color: '#cccccc',
    textTransform: 'capitalize',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-end',
  },
  accept: {
    color: '#2196f3',
    cursor: 'pointer',
    '&:hover': {
      color: '#6ec6ff',
    },
  },
  person: {
    color: '#2a3d41',
  },
  reject: {
    color: '#e53935',
    cursor: 'pointer',
    '&:hover': {
      color: '#ff6f60',
    },
  },
  warning: {
    fontSize: 14,
    color: '#999',
    alignSelf: 'center',
    margin: 20,
  },
  rightText: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-end',
  },
  customIcon: {
    height: '24px',
    width: '24px',
    display: 'block',
    'border-radius': '50%',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '4fr 1fr',
    gridGap: 0,
  },
};

class FoldersContainer extends Component {
  constructor(props) {
    super(props);
    this.acceptInv = this.acceptInv.bind(this);
    this.rejectInv = this.rejectInv.bind(this);
    this.state = {
      loading: true,
    };
  }

  componentWillMount() {
    this.props.fetchGetInvitations().then(() => {
      this.setState({ loading: false });
    });
  }

  acceptInv(id) {
    const defaultFolder = this.props.folders.find(
      folder => folder.name === '<DefaultFolder>',
    );
    this.props.fetchAcceptInvitation(id, { folder_id: defaultFolder.id })
      .then((response) => {
        if (response.ok) {
          this.props.notifySuccess('You joined the project succesfully');
        }
      });
  }

  rejectInv(id) {
    const { selectedLanguage } = this.props;
    this.props.fetchRejectInvitation(id)
      .then((response) => {
        if (!response.body.accepted) {
          this.props.notifySuccess(language[selectedLanguage].home.deleteInvitation);
        }
      });
  }

  render() {
    const {
      classes, invitations, selectedLanguage,
    } = this.props;
    if (this.state.loading) {
      return (
        <Fragment>
          <Typography className={classes.title}>
            {language[selectedLanguage].home.invitations}
          </Typography>
          <div className={classes.loadingContainer}>
            <CircularProgress style={{ position: 'unset' }} />
          </div>
        </Fragment>
      );
    }
    return (

      <Fragment>
        <Grid container>
          <Typography className={classes.title}>
            {language[selectedLanguage].home.invitations}
          </Typography>
        </Grid>
        <Divider style={{ backgroundColor: '#CCC' }} />
        <div className={classes.container}>
          {invitations.map(o => (
            <Card key={o.id} square style={{ width: '100%' }}>
              <Fragment key={o.id}>
                <div style={{ padding: '20px' }}>
                  <div>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                      <div style={{ display: 'flex' }}>
                        {(!o.sender_image || o.sender_image === 'null')
                          && (
                            <img alt="" className={classes.customIcon} src={BlankProfile} />
                          )}
                        {(o.sender_image && o.sender_image !== 'null')
                          && (
                            <img alt="" className={classes.customIcon} src={o.sender_image} />
                          )}
                        <Typography className={classes.name}>

                          &nbsp;
                          {truncateString(o.sender_name, 100)}
                        </Typography>
                      </div>
                      <Typography className={classes.date}>
                        {moment(o.date_sent).format('D/M/YYYY')}
                      </Typography>
                    </div>
                    <div style={{ display: 'grid', gridTemplateRows: '1fr, 1fr', gridGap: '10px' }}>
                      <div style={{ display: 'flex' }}>
                        <Typography className={classes.text}>

                          {language[selectedLanguage].home.hasInvited}
                          &nbsp;
                          <i>{o.project_name}</i>

                          &nbsp;
                          {language[selectedLanguage].home.asA}
                          &nbsp;
                          <i>{o.role}</i>
                        </Typography>
                      </div>
                      <div>
                        <AcceptIcon
                          onClick={() => this.acceptInv(o.id)}
                          className={classes.accept}
                          style={{ float: 'right' }}
                        />
                        <RejectIcon
                          onClick={() => this.rejectInv(o.id)}
                          className={classes.reject}
                          style={{ float: 'right' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Divider style={{ backgroundColor: '#CCC' }} />
              </Fragment>
            </Card>
          ))}
          {
            !invitations.length
            && (
              <Typography className={classes.warning}>

                {language[selectedLanguage].home.noInvitations}
              </Typography>
            )
          }
        </div>
      </Fragment>
    );
  }
}

FoldersContainer.propTypes = {
  classes: PropTypes.any.isRequired,
  invitations: PropTypes.array.isRequired,
  folders: PropTypes.array.isRequired,
  fetchGetInvitations: PropTypes.func.isRequired,
  notifySuccess: PropTypes.func.isRequired,
  fetchRejectInvitation: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  fetchAcceptInvitation: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    invitations: allInvitations(ownProps, state),
    selectedLanguage: state.user.language,
    folders: allFolders(ownProps, state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchGetInvitations,
      fetchRejectInvitation,
      notifySuccess,
      fetchAcceptInvitation,
    },
    dispatch,
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(FoldersContainer)),
);
