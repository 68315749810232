import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Input from '../Input';
import InputLabel from '../Input/InputLabel';
import FormItem from '../Form/FormItem';
import FormHelperText from '../Form/FormHelperText';

/* eslint-disable react/require-default-props */
const styles = {
  cssLabel: {
    '&$cssFocused': {
      color: '#ea5941',
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#ea5941',
    },
  },
};

class TextField extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    if (this.props.onChange != null) {
      this.props.onChange(value);
    }
  }

  render() {
    const {
      type,
      disabled,
      required,
      placeholder,
      label,
      maxLength,
      fullWidth,
      errorMessage,
      id,
      value,
      autoFocus,
      classes,
      onKeyDown,
    } = this.props;

    let error = false;
    let helperText = null;

    if (errorMessage != null) {
      error = true;
      helperText = errorMessage;
    }

    return (
      <FormItem
        error={error}
        disabled={disabled}
        required={required}
        fullWidth={fullWidth}
      >
        <InputLabel
          htmlFor={id}
          FormLabelClasses={{
            root: classes.cssLabel,
            focused: classes.cssFocused,
          }}
          shrink={true}
        >
          {label}
        </InputLabel>
        <Input
          id={id}
          value={value}
          onChange={this.onChange}
          placeholder={placeholder}
          maxLength={maxLength}
          type={type}
          autoFocus={autoFocus}
          onKeyDown={onKeyDown}
          classes={{
            underline: classes.cssUnderline,
          }}
        />
        <FormHelperText>
          {
            helperText
          }
        </FormHelperText>
      </FormItem>
    );
  }
}

TextField.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  fullWidth: PropTypes.bool,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

export default withStyles(styles)(TextField);
