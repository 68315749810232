import {
    TOGGLE_CREATE_MODAL_MILESTONE,
    TOGGLE_EDIT_MODAL_MILESTONE,
    TOGGLE_DELETE_MODAL_MILESTONE,
} from '../../constants/modals/milestones';

export default (state = {
    selectedMilestone: null,
    milestoneCreating: false,
    milestoneEditing: false,
    milestoneDeleting: false,
}, action) => {
    const { type, payload } = action;

    switch (type) {
        case TOGGLE_CREATE_MODAL_MILESTONE: {
            const milestoneCreating = !state.milestoneCreating;
            const selectedMilestone = payload.body ? payload.body : null;
            return {
                ...state,
                milestoneCreating,
                selectedMilestone,
            };
        }
        case TOGGLE_EDIT_MODAL_MILESTONE: {
            const milestoneEditing = !state.milestoneEditing;
            const selectedMilestone = payload.body ? payload.body : null;
            return {
                ...state,
                milestoneEditing,
                selectedMilestone,
            };
        }
        case TOGGLE_DELETE_MODAL_MILESTONE: {
            const milestoneDeleting = !state.milestoneDeleting;
            const selectedMilestone = payload.body ? payload.body : null;

            return {
                ...state,
                milestoneDeleting,
                selectedMilestone,
            };
        }
        default: {
            return state;
        }
    }
};
