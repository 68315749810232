import {
  TASK_OPEN_NAME,
  TASK_OPEN_DESCRIPTION,
  TASK_OPEN_MEMBERS,
  TASK_OPEN_MILESTONE,
  TASK_OPEN_LABELS,
  TASK_OPEN_COMMITS,
  TASK_OPEN_DEADLINE,
  TASK_OPEN_STARTING_DATE,
  TASK_OPEN_ROADBLOCK,
  TASK_OPEN_PRIORITY,
  TASK_OPEN_EXPECTED_HOURS,
  TASK_OPEN_CHECKLIST,
  TASK_SEARCH,
  TASK_OPEN_ZMARTBOT_HELP,
  TASK_SEND_ZMARTBOT_MESSAGE,
} from '../constants/task';

import {
  TOGGLE_EDIT_MODAL_TASK,
} from '../constants/board';

export default (state = {
  openName: false,
  openDescription: false,
  openMembers: false,
  openMilestone: false,
  openLabels: false,
  openCommits: false,
  openDeadline: false,
  openStartingDate: false,
  openRoadblock: false,
  openExpectedHours: false,
  openPriority: false,
  openChecklist: false,
  openZmartBotHelp: false,
  zmartBotHelpMessage: '',
  search: '',
}, action) => {
  const { type, payload } = action;

  switch (type) {
    case TASK_OPEN_NAME: {
      return {
        ...state,
        openName: !state.openName,
      };
    }
    case TASK_OPEN_DESCRIPTION: {
      return {
        ...state,
        openDescription: !state.openDescription,
      };
    }
    case TASK_OPEN_MEMBERS: {
      return {
        ...state,
        openMembers: !state.openMembers,
      };
    }
    case TASK_OPEN_MILESTONE: {
      return {
        ...state,
        openMilestone: !state.openMilestone,
      };
    }
    case TASK_OPEN_LABELS: {
      return {
        ...state,
        openLabels: !state.openLabels,
      };
    }
    case TASK_OPEN_COMMITS: {
      return {
        ...state,
        openCommits: !state.openCommits,
      };
    }
    case TASK_OPEN_DEADLINE: {
      return {
        ...state,
        openDeadline: !state.openDeadline,
      };
    }
    case TASK_OPEN_STARTING_DATE: {
      return {
        ...state,
        openStartingDate: !state.openStartingDate,
      };
    }
    case TASK_OPEN_ROADBLOCK: {
      return {
        ...state,
        openRoadblock: !state.openRoadblock,
      };
    }
    case TASK_OPEN_EXPECTED_HOURS: {
      return {
        ...state,
        openExpectedHours: !state.openExpectedHours,
      };
    }
    case TASK_OPEN_PRIORITY: {
      return {
        ...state,
        openPriority: !state.openPriority,
      };
    }
    case TASK_OPEN_CHECKLIST: {
      return {
        ...state,
        openChecklist: !state.openChecklist,
      };
    }
    case TASK_SEARCH: {
      return {
        ...state,
        search: payload.body.text ? payload.body.text : '',
      };
    }
    case TASK_OPEN_ZMARTBOT_HELP: {
      return {
        ...state,
        openZmartBotHelp: !state.openZmartBotHelp,
      };
    }
    case TASK_SEND_ZMARTBOT_MESSAGE: {
      return {
        ...state,
        zmartBotHelpMessage: payload.body.zmartBotHelpMessage,
      };
    }
    case TOGGLE_EDIT_MODAL_TASK: {
      return {
        ...state,
        openName: false,
        openDescription: false,
        openMembers: false,
        openMilestone: false,
        openLabels: false,
        openCommits: false,
        openDeadline: false,
        openRoadblock: false,
        openExpectedHours: false,
        openPriority: false,
        openChecklist: false,
        openZmartBotHelp: false,
        zmartBotHelpMessage: '',
      };
    }
    case 'PUSHER_DELETE_TASK': {
      return {
        ...state,
        openName: false,
        openDescription: false,
        openMembers: false,
        openMilestone: false,
        openLabels: false,
        openCommits: false,
        openDeadline: false,
        openRoadblock: false,
        openExpectedHours: false,
        openPriority: false,
        openChecklist: false,
        openZmartBotHelp: false,
        zmartBotHelpMessage: '',
      };
    }
    default: {
      return state;
    }
  }
};
