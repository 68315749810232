import { combineReducers } from 'redux';

import folders from './folders';
import projects from './projects';
import columns from './columns';
import boards from './boards';
import invitations from './invitations';
import members from './members';
import archived from './archived';
import logs from './logs';
import labels from './labels';
import milestones from './milestones';
import chatbot from './chatbot';

const modalsReducer = combineReducers({
  folders,
  projects,
  columns,
  boards,
  invitations,
  members,
  archived,
  logs,
  labels,
  milestones,
  chatbot,
});

export default modalsReducer;
