import {
  SELECT_PROJECT,
  SELECT_BOARD,
  SELECT_BOARD_GANTT,
  SELECT_TASK,
  SELECT_MILESTONE,
  UNSELECT_ALL,
  TOGGLE_DROPDOWN_SETTING,
  TOGGLE_BOARD_SEARCH,
  USER_SEARCH,
  CHANGE_LANGUAGE,
} from '../constants/user';

export const selectProject = (id) => {
  return {
    type: SELECT_PROJECT,
    payload: {
      body: {
        id,
      },
    },
  };
};

export const selectBoard = (id) => {
  return {
    type: SELECT_BOARD,
    payload: {
      body: {
        id,
      },
    },
  };
};

export const selectBoardGantt = (id) => {
  return {
    type: SELECT_BOARD_GANTT,
    payload: {
      body: {
        id,
      },
    },
  };
};


export const selectTask = (id) => {
  return {
    type: SELECT_TASK,
    payload: {
      body: {
        id,
      },
    },
  };
};

export const selectMilestone = (id) => {
  return {
    type: SELECT_MILESTONE,
    payload: {
      body: {
        id,
      },
    },
  };
};

export const toggleBoardSearch = (active) => {
  return {
    type: TOGGLE_BOARD_SEARCH,
    payload: {
      body: {
        active,
      },
    },
  };
};

export const unselectAll = () => {
  return {
    type: UNSELECT_ALL,
  };
};

export const toggleDropDownSetting = () => {
  return {
    type: TOGGLE_DROPDOWN_SETTING,
  };
};

export const userSearch = body => ({
  type: USER_SEARCH,
  payload: {
    body,
  },
});

export const changeLanguage = language => ({
  type: CHANGE_LANGUAGE,
  payload: {
    language,
  },
});
