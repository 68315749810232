// in ActionProvider.jsx
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

const URL = process.env.REACT_APP_API_URL

const EquipoDocente = ({ auth }) => {
  const [data, setData] = useState({});
  const [currentSemester, setCurrentSemester] = useState("");

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    let semester = 0;
    if (month >= 0 && month <= 5) {
      semester = 1;
    } else {
      semester = 2;
    }
    setCurrentSemester(`${year}-${semester}`);
    const fetchData = async () => {
      const response = await fetch(`${URL}/chatbot_params?semester=${semester}&year=${year}`, {
        method: 'GET',
        headers: {
          uid: auth.uid,
          client: auth.client,
          'access-token': auth.token
        },
      })
      const data = await response.json();
      setData(data);
    }
    fetchData();

  }, []);
  

  return (
    <div className='react-chatbot-kit-chat-bot-message-container'>
      <div className='react-chatbot-kit-chat-bot-message'>
      Equipo Docente del Semestre {currentSemester}: <br/>
      <br/>
      <strong>Profesores</strong>
      <br/>
      <br/>
      {data.team && data.team.map((docente) => docente.work_position=="Profesor" && (
        <span>
          {docente.name}, {docente.email} <br/>
        </span>
      ))}
      <br/>
      <strong>Ayudantes Expertos</strong>
      <br/>
      <br/>
      {data.team && data.team.map((docente) => docente.work_position=="Ayudante Experto" && (
        <span>
          {docente.name}, {docente.email} <br/>
        </span>
      ))}
      <br/>
      <strong>Coaches</strong>
      <br/>
      <br/>
      {data.team && data.team.map((docente) => docente.work_position=="Coach" && (
        <span>
          {docente.name}, {docente.email} <br/>
        </span>
      ))}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    auth: state.auth,
  };
}


export default connect(mapStateToProps)(EquipoDocente);