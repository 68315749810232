import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';

import thunkMiddleware from 'redux-thunk';
import fetchMiddleware from './fetchMiddleware';
import authMiddleware from './authMiddleware';
import pusherMiddleware from './pusherMiddleware';
import rootEpic from './epics';

import reducer from '../reducers';

const epicMiddleware = createEpicMiddleware();
const { logger } = require('redux-logger');

const isBrowser = typeof window !== 'undefined';
const isProduction = true;

const composeEnhancers = (isBrowser && !isProduction)
  ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)
  : compose;

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

// save middlewares
const middlewares = [
  fetchMiddleware,
  pusherMiddleware,
  thunkMiddleware,
  authMiddleware,
  epicMiddleware,
];

middlewares.push(logger);

export default function configureStore() {
  const store = createStore(
    reducer,
    composeEnhancers(
      applyMiddleware(
        ...middlewares,
      ),
    ),
  );
  epicMiddleware.run(rootEpic);
  return store;
}
