import React from "react";
import chatbotIcon from '../../../assets/images/isotipo-normal.png';
import "./WelcomeView.css"

function WelcomeView({ setCurrentMessage }) {
    return (    
    <div className="chatmodal-nochat-selected-container">
        <img src={chatbotIcon} alt="logo chat" className="chatmodal-main-logo"/>
        <div className="chatmodal-starting-options">
            <button className="chatmodal-option-button" 
                onClick={() => setCurrentMessage("Quiero informacion sobre scrum")}
            >
                Informacion sobre scrum
            </button>
            <button className="chatmodal-option-button"
                onClick={() => setCurrentMessage("Quiero preguntar sobre un framework")}
            >
                Preguntar por frameworks
            </button>
            <button className="chatmodal-option-button"
                onClick={() => setCurrentMessage("Quiero algunos consejos para programar")}
            >
                Consejos para programar
            </button>
        </div>
    </div>);
};

export default WelcomeView;