import { createSelector } from 'redux-orm';

import orm from '../reducers/orm';

// const getRef = obj => (obj != null ? obj.ref : null);

export const allFolders = createSelector(
  orm,
  (_, state) => state.orm,
  (session) => {
    return session.Folder
      .all()
      .orderBy(o => o.name.toUpperCase())
      .toRefArray();
  },
);

export const selectArchivedFolders= createSelector(
  orm,
  (_, state) => state.orm,
  (session) => {
    return session.Folder
      .filter(o => o.is_archived === true)
      .all()
      .orderBy(o => o.name.toUpperCase())
      .toRefArray();
  },
);

export const projectCountByFolder = createSelector(
  orm,
  (_, state) => state.orm,
  props => props.folder.id,
  (session, folderId) => {
    return session.Project
      .filter(o => o.folder_id === folderId)
      .count();
  },
);

export const projectCount = createSelector(
  orm,
  (_, state) => state.orm,
  (session) => {
    let total = 0;
    const folders = session.Folder
      .all()
      .orderBy(o => o.name.toUpperCase())
      .toRefArray();
    folders.forEach((folder) => {
      total += session.Project
        .filter(o => o.folder_id === folder.id)
        .count();
    });
    return total;
  },
);

export const oneFolder = createSelector(
  orm,
  (_, state) => state.orm,
  (_, state) => state.modal.folders.selectedFolder,
  (session, folderId) => {
    return session.Folder
      .get(o => o.id === folderId)
      .ref;
  },
);

/*
returns true in case of folders in orm, else returns false.
WARNING: if for any case the user has 0 folders, the app will be
able to enter a loop of fetching folders waiting to get at least one.
*/
export const fetchedFolders = createSelector(
  orm,
  (_, state) => state.orm,
  (session) => {
    return session.Folder.count() > 0;
  },
);