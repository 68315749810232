import {
  FETCH_REGISTER,
  FETCH_AUTH_LOGIN,
  FETCH_AUTH_LOGOUT,
  FETCH_CONFIRMATION,
  FETCH_GOOGLE_AUTH,
  FETCH_FORGOT_PASSWORD,
  FETCH_PASSWORD_RESET,
  FETCH_UPDATE_USER,
  FETCH_UPDATE_PHOTO,
  FETCH_RESEND_CONFIRMATION,
} from '../../constants/api/auth';

export const register = (email, password, passwordconfirmation) => ({
  type: FETCH_REGISTER,
  fetch: true,
  url: '/auth/',
  params: {
    method: 'POST',
    headers: {
      email,
      password,
      passwordconfirmation,
    },
  },
});

export const login = (email, password) => ({
  type: FETCH_AUTH_LOGIN,
  fetch: true,
  url: '/auth/sign_in',
  params: {
    method: 'POST',
    headers: {
      email,
      password,
    },
  },
});

export const logout = (uid, client, token) => ({
  type: FETCH_AUTH_LOGOUT,
  fetch: true,
  url: '/auth/sign_out',
  params: {
    method: 'DELETE',
    headers: {
      uid,
      client,
      'access-token': token,
    },
  },
});

export const confirmation = confirmationToken => ({
  type: FETCH_CONFIRMATION,
  fetch: true,
  url: '/users/confirm',
  params: {
    method: 'POST',
    body: {
      confirmation_token: confirmationToken,
    },
  },
});

export const googleAuth = body => ({
  type: FETCH_GOOGLE_AUTH,
  fetch: true,
  url: '/google_oauth2/callback',
  params: {
    method: 'POST',
    body,
  },
});

export const sendPasswordReset = body => ({
  type: FETCH_FORGOT_PASSWORD,
  fetch: true,
  url: '/users/forgot',
  params: {
    method: 'POST',
    body,
  },
});

export const sendResendConfirmation = body => ({
  type: FETCH_RESEND_CONFIRMATION,
  fetch: true,
  url: '/users/resend',
  params: {
    method: 'POST',
    body,
  },
});

export const resetPassword = (id, body) => ({
  type: FETCH_PASSWORD_RESET,
  fetch: true,
  url: `/users/${id}/reset`,
  params: {
    method: 'POST',
    body,
  },
});

export const updateUser = body => ({
  type: FETCH_UPDATE_USER,
  fetch: true,
  url: '/auth',
  params: {
    method: 'PUT',
    body,
  },
});

export const updatePhoto = body => ({
  type: FETCH_UPDATE_PHOTO,
  fetch: true,
  url: '/users/image',
  params: {
    method: 'POST',
    body,
  },
});

