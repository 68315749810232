import {
  TOGGLE_SHOW_MODAL_LOGS,
  LAST_HOVER_PROJECT,
} from '../../constants/modals/logs';

export default (state = {
  selectedProject: '',
  logsShowing: false,
}, action) => {
  const { type, payload } = action;

  switch (type) {
    // Modal route: Project
    case TOGGLE_SHOW_MODAL_LOGS: {
      const logsShowing = !state.logsShowing;
      const selectedProject = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        logsShowing,
        selectedProject,
      };
    }
    case LAST_HOVER_PROJECT: {
      return {
        ...state,
        selectedProject: payload.body.projectId,
      };
    }
    default: {
      return state;
    }
  }
};
