export const FETCH_GET_MILESTONES = 'FETCH_GET_MILESTONES';
export const FETCH_GET_MILESTONE = 'FETCH_GET_MILESTONE';
export const FETCH_CREATE_MILESTONE = 'FETCH_CREATE_MILESTONE';
export const FETCH_DELETE_MILESTONE = 'FETCH_DELETE_MILESTONE';
export const FETCH_UPDATE_MILESTONE = 'FETCH_UPDATE_MILESTONE';
export const FETCH_GET_MILESTONE_TASKS = 'FETCH_GET_MILESTONE_TASKS';

export const FETCH_GET_ALL_MILESTONES = 'FETCH_GET_ALL_MILESTONES';
export const FETCH_GET_ALL_PROJECT_MILESTONES = 'FETCH_GET_ALL_PROJECT_MILESTONES';

export const FETCH_MILESTONE_ASSIGN = 'FETCH_MILESTONE_ASSIGN';
export const FETCH_MILESTONE_UNASSIGN = 'FETCH_MILESTONE_UNASSIGN';
export const FETCH_GET_MILESTONES_HOURS_BOARD = 'FETCH_GET_MILESTONES_HOURS_BOARD';
export const FETCH_GET_MILESTONES_PERCENTAGE_HOURS = 'FETCH_GET_MILESTONES_PERCENTAGE_HOURS';