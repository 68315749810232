import {
  TASK_OPEN_LOGS,
  TASK_OPEN_REPORT_HOURS,
  TASK_OPEN_REPORTED_HOURS,
  TASK_OPEN_COMMENTS,
  TASK_OPEN_ATTACHMENT,
} from '../constants/task';

const defaultState = {
  openLogs: false,
  openReportHours: false,
  openReportedHours: false,
  openComments: false,
  openAttachments: false,
};

export default (state = {
  ...defaultState,
}, action) => {
  const { type } = action;

  switch (type) {
    case TASK_OPEN_LOGS: {
      return {
        ...defaultState,
        openLogs: true,
      };
    }
    case TASK_OPEN_REPORT_HOURS: {
      return {
        ...state,
        openReportHours: !state.openReportHours,
      };
    }
    case TASK_OPEN_REPORTED_HOURS: {
      return {
        ...defaultState,
        openReportedHours: true,
      };
    }
    case TASK_OPEN_COMMENTS: {
      return {
        ...defaultState,
        openComments: true,
      };
    }
    case TASK_OPEN_ATTACHMENT: {
      return {
        ...defaultState,
        openAttachments: true,
      };
    }
    case 'PUSHER_DELETE_TASK': {
      return {
        ...defaultState,
      };
    }
    default: {
      return state;
    }
  }
};
