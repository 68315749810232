import { fk, attr, Model } from 'redux-orm';

import {
  FETCH_CREATE_BOARD,
  FETCH_UPDATE_BOARD,
  FETCH_DELETE_BOARD,
  FETCH_GET_ALL_BOARDS,
  FETCH_GET_ALL_PROJECT_BOARDS,
} from '../../constants/api/boards';

import {
  FETCH_CREATE_LABEL,
  FETCH_DELETE_LABEL,
} from '../../constants/api/labels';

import {
  MODEL_UPDATE_BOARD_ORDER,
} from '../../constants/order';

class Board extends Model {
  static create(props) {
    if (props.hasOwnProperty(this.idAttribute) && this.idExists(props[this.idAttribute])) {
      const model = this.withId(props.id);
      return model.update(props);
    }
    return super.create(props);
  }

  static reducer(action, modelClass) {
    const { type, payload } = action;
    switch (type) {
      case `${FETCH_CREATE_LABEL}_SUCCESS`: {
        const board = modelClass.withId(payload.body.board_id);
        const newBoard = Object.assign({}, board, {
          labels: board.labels.slice(0),
        });
        newBoard.labels.push(payload.body.label_id);
        modelClass.withId(payload.body.board_id).update(newBoard);
      }
        break;
      case `${FETCH_DELETE_LABEL}_SUCCESS`: {
        const board = modelClass.withId(payload.body.board_id);
        const newBoard = Object.assign({}, board, {
          labels: board.labels.filter(labelId => labelId !== payload.body.label_id),
        });
        modelClass.withId(payload.body.board_id).update(newBoard);
      }
        break;
      case `${FETCH_CREATE_BOARD}_SUCCESS`:
        modelClass.create({ ...payload.body, labels: [] });
        break;
      case `${FETCH_UPDATE_BOARD}_SUCCESS`:
        modelClass.withId(payload.body.id).update(payload.body);
        break;
      case `${FETCH_DELETE_BOARD}_SUCCESS`:
        modelClass.withId(payload.body.id).delete();
        break;
      case `${FETCH_GET_ALL_BOARDS}_SUCCESS`: {
        const array = payload.body.results || payload.body;
        for (let i = 0; i < array.length; i += 1) {
          const obj = array[i];
          modelClass.create({ ...obj, project: obj.project_id });
        }
        break;
      }
      case `${FETCH_GET_ALL_PROJECT_BOARDS}_SUCCESS`: {
        const array = payload.body.results || payload.body;
        for (let i = 0; i < array.length; i += 1) {
          const obj = array[i];
          modelClass.create(obj);
        }
        break;
      }
      case `${MODEL_UPDATE_BOARD_ORDER}`:
        modelClass.withId(payload.body.id).update({ position: payload.body.position });
        break;
      default:
        break;
    }
    return null;
  }
}

Board.modelName = 'Board';

Board.fields = {
  id: attr(),
  name: attr(),
  description: attr(),
  position: attr(),
  labels: attr(), // [Array]
  project_id: fk('Project', 'boards'),
};

export default Board;
