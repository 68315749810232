import React, { Fragment, Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import NetworkModal from './NetworkModal';

const inBrowser = typeof navigator !== 'undefined';

// these browsers don't fully support navigator.onLine, so we need to use a polling backup
const unsupportedUserAgentsPattern = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;

const ping = ({ url, timeout }) => {
  return new Promise((resolve) => {
    const isOnline = () => resolve(true);
    const isOffline = () => resolve(false);

    const xhr = new XMLHttpRequest();

    xhr.onerror = isOffline;
    xhr.ontimeout = isOffline;
    xhr.onload = () => {
      const response = xhr.responseText.trim();
      if (!response) {
        isOffline();
      } else {
        isOnline();
      }
    };

    xhr.open('GET', url);
    xhr.timeout = timeout;
    xhr.send();
  });
};

const propTypes = {
  children: PropTypes.node.isRequired,
  polling: PropTypes.oneOfType([
    PropTypes.shape({
      url: PropTypes.string,
      interval: PropTypes.number,
      timeout: PropTypes.number,
    }),
    PropTypes.bool,
  ]),
};

const defaultProps = {
  polling: true,
};

const defaultPollingConfig = {
  enabled: inBrowser && unsupportedUserAgentsPattern.test(navigator.userAgent),
  url: 'https://ipv4.icanhazip.com/',
  timeout: 5000,
  interval: 5000,
};

// base class that detects offline/online changes
class NetworkWrapper extends Component {
  constructor() {
    super();
    this.state = {
      online:
        inBrowser && typeof navigator.onLine === 'boolean'
          ? navigator.onLine
          : true,
    };
    // bind event handlers
    this.goOnline = this.goOnline.bind(this);
    this.goOffline = this.goOffline.bind(this);
  }

  componentDidMount() {
    window.addEventListener('online', this.goOnline);
    window.addEventListener('offline', this.goOffline);

    if (this.getPollingConfig().enabled) {
      this.startPolling();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.goOnline);
    window.removeEventListener('offline', this.goOffline);

    if (this.pollingId) {
      this.stopPolling();
    }
  }

  getPollingConfig() {
    switch (this.props.polling) {
      case true:
        return defaultPollingConfig;
      case false:
        return { enabled: false };
      default:
        return Object.assign({}, defaultPollingConfig, this.props.polling);
    }
  }

  goOnline() {
    if (!this.state.online) {
      this.setState({ online: true });
    }
  }

  goOffline() {
    if (this.state.online) {
      this.setState({ online: true });
    }
  }

  startPolling() {
    const { interval } = this.getPollingConfig();
    this.pollingId = setInterval(() => {
      const { url, timeout } = this.getPollingConfig();
      ping({ url, timeout }).then(online => (online ? this.goOnline() : this.goOffline()));
    }, interval);
  }

  stopPolling() {
    clearInterval(this.pollingId);
  }

  render() {
    const { children } = this.props;
    return (
      <Fragment>
        <NetworkModal online={this.state.online}>
          {children}
        </NetworkModal>
      </Fragment>
    );
  }
}

NetworkWrapper.propTypes = propTypes;
NetworkWrapper.defaultProps = defaultProps;

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkWrapper);
