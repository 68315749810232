export const FETCH_CREATE_PROJECT = 'FETCH_CREATE_PROJECT';
export const FETCH_UPDATE_PROJECT = 'FETCH_UPDATE_PROJECT';
export const FETCH_UPDATE_PROJECT_ORDER = 'FETCH_UPDATE_PROJECT_ORDER';
export const FETCH_DELETE_PROJECT = 'FETCH_DELETE_PROJECT';
export const FETCH_GET_PROJECT = 'FETCH_GET_PROJECT';
export const FETCH_GET_ALL_PROJECTS = 'FETCH_GET_ALL_PROJECTS';
export const FETCH_UPDATE_PROJECT_FAVORITE = 'FETCH_UPDATE_PROJECT_FAVORITE';
export const FETCH_GITHUB_CONNECT = 'FETCH_GITHUB_CONNECT';
export const FETCH_GITHUB_CONFIRMATION = 'FETCH_GITHUB_CONFIRMATION';
export const FETCH_GITHUB_REPO = 'FETCH_GITHUB_REPO';
export const FETCH_PROJECT_REPO = 'FETCH_PROJECT_REPO';
export const FETCH_GITHUB_REMOVE = 'FETCH_GITHUB_REMOVE';
export const FETCH_PROJECT_REPO_ASSIGN = 'FETCH_PROJECT_REPO_ASSIGN';
export const FETCH_PROJECT_REPO_UNASSIGN = 'FETCH_PROJECT_REPO_UNASSIGN';
export const FETCH_TASKS_PROJECT_BETWEEN_DATES = 'FETCH_TASKS_PROJECT_BETWEEN_DATES';

