import { fetchWithTimeout, getStringQueryParams } from '../utils';

const isProduction = true;

const fetchMiddleware = store => next => (action) => {
  if (action.fetch === true) {
    const { dispatch } = store;
    const {
      type, url, params, ignoreAuthorization, queryParams,
    } = action;
    const { auth } = store.getState();
    const {
      token,
      client,
      expiry,
      uid,
    } = auth;
    let headers = params.headers || {};
    headers = Object.assign(headers,
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      });
    if (token && !ignoreAuthorization) {
      headers = Object.assign(headers,
        {
          'access-token': token,
          client,
          expiry,
          uid,
        });
    }
    const config = Object.assign({}, {
      method: params.method,
      headers,
    });
    if (params.hasOwnProperty('body')) {
      config.body = JSON.stringify(params.body);
    }
    if (params.hasOwnProperty('header')) {
      config.header = JSON.stringify(params.header);
    }
    const baseUrl = process.env.REACT_APP_API_URL;
    const absoluteUrl = baseUrl + url + getStringQueryParams(queryParams);
    const timeout = type.includes('FETCH_ADD_MEMBER') ? 30000 : 7000;
    dispatch({ type: `${type}_REQUEST`, url, params });
    return fetchWithTimeout(absoluteUrl, config, timeout)
      .then((response) => {
        return response.json()
          .then((json) => {
            const payload = {
              ok: response.ok,
              status: response.status,
              body: json,
              headers: response.headers,
            };
            if (response.ok) {
              dispatch({ type: `${type}_SUCCESS`, payload });
            } else {
              dispatch({ type: `${type}_FAILURE`, payload });
            }
            return payload;
          })
          .catch((err) => {
            const payload = {
              ok: response.ok,
              status: response.status,
              body: null,
              error: err,
            };
            dispatch({ type: `${type}_FAILURE`, payload });
            return payload;
          });
      })
      .catch((err) => {
        if (err === 'timeout') {
          dispatch({ type: `${type}_TIMEOUT`, payload: { ok: false } });
        } else if (!isProduction) {
          const payload = { body: { errors: ['SERVER ERROR'] } };
          dispatch({ type: `${type}_FAILURE`, payload });
        }
        return { ok: false };
      });
  }
  return next(action);
};

export default fetchMiddleware;
