export const FETCH_REGISTER = 'USERS_REGISTER';
export const FETCH_AUTH_LOGIN = 'FETCH_AUTH_LOGIN';
export const FETCH_AUTH_LOGOUT = 'FETCH_AUTH_LOGOUT';
export const FETCH_CONFIRMATION = 'FETCH_CONFIRMATION';
export const FETCH_GOOGLE_AUTH = 'FETCH_GOOGLE_AUTH';
export const FETCH_FORGOT_PASSWORD = 'FETCH_FORGOT_PASSWORD';
export const FETCH_RESEND_CONFIRMATION = 'FETCH_RESEND_CONFIRMATION';
export const FETCH_PASSWORD_RESET = 'FETCH_PASSWORD_RESET';
export const FETCH_UPDATE_USER = 'FETCH_UPDATE_USER';
export const FETCH_UPDATE_PHOTO = 'FETCH_UPDATE_PHOTO';
export const FETCH_UPDATE_TERMS = '';
