export const START_DRAG = 'START_DRAG';
export const TOGGLE_DROPDOWN_TASK = 'TOGGLE_DROPDOWN_TASK';
export const TOGGLE_DROPDOWN_COLUMN = 'TOGGLE_DROPDOWN_COLUMN';

export const TOGGLE_CREATE_MODAL_BOARD = 'TOGGLE_CREATE_MODAL_BOARD';
export const TOGGLE_EDIT_MODAL_BOARD = 'TOGGLE_CREATE_MODAL_BOARD';
export const TOGGLE_DELETE_MODAL_BOARD = 'TOGGLE_DELETE_MODAL_BOARD';

export const TOGGLE_CREATE_MODAL_COLUMN = 'TOGGLE_CREATE_MODAL_COLUMN';
export const TOGGLE_EDIT_MODAL_COLUMN = 'TOGGLE_CREATE_MODAL_COLUMN';
export const TOGGLE_DELETE_MODAL_COLUMN = 'TOGGLE_DELETE_MODAL_COLUMN';

export const TOGGLE_ASSIGN_MODAL_TASK = 'TOGGLE_ASSIGN_MODAL_TASK';
export const TOGGLE_LABEL_MODAL_TASK = 'TOGGLE_LABEL_MODAL_TASK';
export const TOGGLE_REPORT_MODAL_TASK = 'TOGGLE_REPORT_MODAL_TASK';
export const TOGGLE_EDIT_MODAL_TASK = 'TOGGLE_EDIT_MODAL_TASK';
export const TOGGLE_CREATE_MODAL_TASK = 'TOGGLE_CREATE_MODAL_TASK';
