import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { BrowserTracing } from "@sentry/tracing";

import App from './App';
import store from './store';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://dceb6cc943bd464a9ed9832c642a65ea@o1262444.ingest.sentry.io/6441373",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

render(
  <Provider store={store()}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
);
