export const FETCH_CREATE_TASK = 'FETCH_CREATE_TASK';
export const FETCH_UPDATE_TASK = 'FETCH_UPDATE_TASK';
export const FETCH_ARCHIVE_TASK = 'FETCH_ARCHIVE_TASK';
export const FETCH_DELETE_TASK = 'FETCH_DELETE_TASK';
export const FETCH_GET_ALL_TASKS = 'FETCH_GET_ALL_TASKS';
export const FETCH_GET_PROJECT_TASKS = 'FETCH_GET_PROJECT_TASKS';
export const FETCH_GET_ALL_PROJECT_TASKS = 'FETCH_GET_ALL_PROJECT_TASKS';
export const FETCH_GET_ALL_ARCHIVED_TASKS = 'FETCH_GET_ALL_ARCHIVED_TASKS';
export const FETCH_GET_TASK_LOGS = 'FETCH_GET_TASK_LOGS';

export const FETCH_UPDATE_TASK_ORDER = 'FETCH_UPDATE_TASK_ORDER';
export const FETCH_UPDATE_TASK_ORDER_AND_COLUMN = 'FETCH_UPDATE_TASK_ORDER_AND_COLUMN';
export const FETCH_GET_TASK_ORDER_BY_COLUMN = 'FETCH_GET_TASK_ORDER_BY_COLUMN';

export const FETCH_GET_GITHUB_COMMITS = 'FETCH_GET_GITHUB_COMMITS';
export const FETCH_POST_TASK_COMMIT = 'FETCH_POST_TASK_COMMIT';
export const FETCH_DELETE_TASK_COMMIT = 'FETCH_DELETE_TASK_COMMIT';
