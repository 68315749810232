import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

import { clearNotification } from '../../../actions/notification';

/* eslint-disable no-unused-vars, react/require-default-props */
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const {
    classes, className, message, onClose, variant, ...other
  } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={(
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
            )}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
});

class CustomizedSnackbars extends React.Component {
  state = {
    open: false,
  };

  componentWillReceiveProps() {
    this.setState({ open: true });
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.clearNotification();
    this.setState({ open: false });
  };

  render() {
    let message;
    let type;
    const {
      errorMessage,
      successMessage,
      errorMsg,
      errorPassword,
      errorEmail,
    } = this.props;
    if (errorMessage) {
      message = errorMessage;
      type = 'error';
    }
    if (successMessage) {
      message = successMessage;
      type = 'success';
    }
    if (errorMsg) {
      message = errorMsg;
      type = 'error';
    }
    if (errorPassword) {
      message = errorPassword;
      type = 'error';
    }
    if (errorEmail) {
      message = errorEmail;
      type = 'error';
    }
    if (message) {
      return (
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={this.state.open}
            autoHideDuration={3000}
            onClose={this.handleClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              variant={type}
              message={message}
            />
          </Snackbar>
        </div>
      );
    }
    return null;
  }
}

CustomizedSnackbars.propTypes = {
  errorMsg: PropTypes.string,
  errorPassword: PropTypes.string,
  errorEmail: PropTypes.string,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  clearNotification: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    errorMsg: state.auth.errorMessage,
    errorPassword: state.auth.errorPassword,
    errorEmail: state.auth.errorEmail,
    errorMessage: state.notification.error,
    successMessage: state.notification.success,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    clearNotification,
  }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles2)(CustomizedSnackbars),
);
