import { switchMap, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';

import {
  FETCH_CREATE_TASK,
} from '../../../constants/api/tasks';

const taskCreateDebounce = action$ => action$.pipe(
  ofType(`${FETCH_CREATE_TASK}_DEBOUNCE`),
  debounceTime(100),
  switchMap(action => Observable.create((observer) => {
    observer.next({
      type: `${FETCH_CREATE_TASK}`,
      fetch: true,
      url: action.url,
      params: action.url,
    });
  })),
);

export default combineEpics(
  taskCreateDebounce,
);
