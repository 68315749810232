import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';

import SidebarLogo from '../components/app/SidebarLogo/index';
import language from '../constants/language';

// import evaluationIcon from '../assets/evaluation.svg';
// import evaluationSelectedIcon from '../assets/evaluation_selected.svg';

import lessonsIcon from '../assets/lessons.svg';
import lessonsSelectedIcon from '../assets/lessons_selected.svg';

import reportIcon from '../assets/graphic.svg';
import reportSelectedIcon from '../assets/graphic_selected.svg';

import milestoneIcon from '../assets/flag.svg';
import milestoneSelectedIcon from '../assets/flag_selected.svg';

import boardIcon from '../assets/board.svg';
import boardSelectedIcon from '../assets/board_selected.svg';

import workIcon from '../assets/work.svg';
import workSelectedIcon from '../assets/work_selected.svg';

const styles = {
  root: {
    flex: 1,
    display: 'flex',
  },
  content: {
    flex: 1,
    backgroundColor: '#f0f0f0',
    overflow: 'auto',
  },
  drawerPaper: {
    position: 'relative',
    backgroundColor: '#d8d8d8',
    border: 'none',
  },
  drawerContent: {
    padding: '20px 15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: 18,
    color: '#fefefe',
    margin: '8px 0px',
    padding: 3,
  },
};

const PrivateComponent = ({
  projectId,
  classes,
  children,
  path,
  selectedLanguage,
}) => {
  const isSelected = endPath => (path.endsWith(endPath));
  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerContent} id={"tour4"}>
          <div id={"tour5"}>
          <Tooltip placement="right" title={language[selectedLanguage].projectSidebar.projectBoards} >
            <Link to={`/project/${projectId}`}>
              <SidebarLogo className={classes.icon} selected={isSelected(':id')} src={boardIcon} srcSelected={boardSelectedIcon}/>
            </Link>
          </Tooltip>
          </div>
          <div id={"tour6"}>
          <Tooltip placement="right" title={language[selectedLanguage].projectSidebar.projectMilestones}>
            <Link to={`/project/${projectId}/milestone`}>
              <SidebarLogo className={classes.icon} selected={isSelected('milestone')} src={milestoneIcon} srcSelected={milestoneSelectedIcon} />
            </Link>
          </Tooltip>
          </div>
          <div id={"tour7"}>
          <Tooltip placement="right" title={language[selectedLanguage].projectSidebar.projectReports}>
            <Link to={`/project/${projectId}/report`}>
              <SidebarLogo className={classes.icon} selected={isSelected('report')} src={reportIcon} srcSelected={reportSelectedIcon} />
            </Link>
          </Tooltip>
          </div>
          <div id={"tour8"}>
          <Tooltip placement="right" title={language[selectedLanguage].projectSidebar.lessons}>
            <Link to={`/project/${projectId}/lessons`}>
              <SidebarLogo
                className={classes.icon} selected={isSelected('lessons')} src={lessonsIcon} srcSelected={lessonsSelectedIcon}/>
            </Link>
          </Tooltip>
          </div>
          <div id={"tour9"}>
          <Tooltip placement="right" title={language[selectedLanguage].projectSidebar.standUpReports}>
            <Link to={`/project/${projectId}/standupreports`}>
              <SidebarLogo
                className={classes.icon} selected={isSelected('standupreports')} src={workIcon} srcSelected={workSelectedIcon}/>
            </Link>
          </Tooltip>
          </div>
        </div>
      </Drawer>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  );
};

PrivateComponent.propTypes = {
  projectId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    projectId: ownProps.match.params.id,
    selectedLanguage: state.user.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrivateComponent)),
);

/* Para agregar evaluations, sumarle:
<Tooltip placement="right" title={language[selectedLanguage].projectSidebar.projectEvaluations}>
  <Link to={`/project/${projectId}/evaluation`}>
    <SidebarLogo
    className={classes.icon} selected={isSelected('evaluation')} src={evaluationIcon}
    srcSelected={evaluationSelectedIcon} />
  </Link>
</Tooltip>
 */