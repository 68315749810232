import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withTheme } from '@material-ui/core/styles';

/* eslint-disable react/require-default-props */
const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const color = '#2580AC';

const CenteredCircularProgress = styled(CircularProgress)`
  color: ${color};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

class BaseButton extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    if (event) event.stopPropagation();
    if (this.props.onClick != null) {
      this.props.onClick(event);
    }
  }

  render() {
    const {
      disabled, children, loading, type, theme, ...rest
    } = this.props;
    const disabledOrLoading = disabled || loading;
    const button = (
      <Button
        {...rest}
        disabled={disabledOrLoading}
        onClick={this.onClick}
        type={type}
        variant="contained"
      >
        {children}
      </Button>
    );
    if (loading) {
      return (
        <Wrapper theme={theme}>
          {button}
          {
            loading
            && <CenteredCircularProgress style={{ color }} size={24} />
          }
        </Wrapper>
      );
    }
    return button;
  }
}

BaseButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

export default withTheme()(BaseButton);
