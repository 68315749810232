import Board from './Board';
import Column from './Column';
import Task from './Task';
import Member from './Member';
import Milestone from './Milestones';
import Label from './Label';
import Folder from './Folder';
import Project from './Project';
import Invitation from './Invitation';
import InvitationSent from './InvitationSent';
import Hour from './Hours';
import Comment from './Comments';
import Attachments from './Attachments';
import Checklist from './Checklists';
import User from './User';

export default {
  Folder,
  Project,
  Invitation,
  InvitationSent,
  Board,
  Column,
  Task,
  Member,
  Milestone,
  Label,
  Hour,
  Comment,
  Checklist,
  Attachments,
  User,
};
