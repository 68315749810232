import { combineReducers } from 'redux';
import { createReducer } from 'redux-orm';

import { FETCH_AUTH_LOGOUT } from '../constants/api/auth';

import app from './app';
import auth from './auth';
import ormjs from './orm';
import user from './user';
import welcome from './welcome';
import notification from './notification';
import modal from './modals';
import board from './board';
import task from './task';
import subTask from './subTask';
import project from './project';

const orm = createReducer(ormjs);

const appReducer = combineReducers({
  app,
  auth,
  orm,
  user,
  welcome,
  notification,
  modal,
  board,
  task,
  subTask,
  project,
});

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === `${FETCH_AUTH_LOGOUT}_REQUEST`) {
    newState = undefined;
  }
  return appReducer(newState, action);
};

export default rootReducer;
