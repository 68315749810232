import { switchMap, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';

import {
  TASK_SEARCH,
} from '../../../constants/task';

const searchBoard = action$ => action$.pipe(
  ofType(TASK_SEARCH),
  debounceTime(500),
  switchMap(action => Observable.create((observer) => {
    observer.next({ payload: action.payload, type: TASK_SEARCH });
  })),
);

export default combineEpics(
  searchBoard,
);
