import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';

/* eslint-disable react/prefer-stateless-function, react/require-default-props */
class FormItem extends Component {
  render() {
    const {
      children, disabled, error, fullWidth, required,
    } = this.props;
    return (
      <FormControl
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        required={required}
      >
        {children}
      </FormControl>
    );
  }
}

FormItem.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default FormItem;
