import React from 'react';
import PropTypes from 'prop-types';

import MaterialDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

/* eslint-disable react/prop-types */
const Dialog = ({
  title, children, footer, clickClose, hideClose, contentStyle, canClose, ...props
}) => (
  <MaterialDialog
    {...props}
  >
    <DialogTitle>
      {hideClose ? title
        : (
          <div>
            {title}
            <IconButton
              onClick={clickClose}
              style={{
                padding: 5,
                right: 2,
                top: 2,
                position: 'absolute',
              }}
            >
              {canClose && <CloseIcon />}
            </IconButton>
          </div>
        )
      }
    </DialogTitle>
    <DialogContent style={contentStyle}>
      {children}
    </DialogContent>
    <DialogActions>
      {footer}
    </DialogActions>
  </MaterialDialog>
);

Dialog.propTypes = {
  title: PropTypes.any,
  footer: PropTypes.node,
  hideClose: PropTypes.bool,
  children: PropTypes.node.isRequired,
  contentStyle: PropTypes.object,
  canClose: PropTypes.bool,
};

Dialog.defaultProps = {
  title: undefined,
  footer: undefined,
  hideClose: false,
  contentStyle: {},
  canClose: true,
};

export default Dialog;
